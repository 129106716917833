@mixin render-ProjectArticle-classes {

    .ProjectArticle {
        position: relative;
        z-index:2;
        max-width:1020px;
        margin-left:auto;
        margin-right:auto;
        margin-top:50px;

        background:white;
        padding-top:1px;

        @include media-breakpoint-up('md') {
            margin-top:85px;
        }
    }

    .ProjectArticle-header {
        margin-top:30px;
        margin-bottom: 30px;
        margin-left:24px;
        margin-right:24px;
        @include media-breakpoint-up('md') {
            margin-left:80px;
            margin-right:80px;
            margin-top:50px;
            margin-bottom: 50px;
        }


    }

    .ProjectArticle-meta {
        font-size:14px;
        @include media-breakpoint-up('md') {
            display:flex;
            font-size:16px;
            justify-content: space-between;
        }
    }

    .ProjectArticle-metaAuthors,
    .ProjectArticle-metaCourses {

        @include media-breakpoint-up('md') {
            border-right: 1px solid map-get($colour-greys, '600');
            padding-right:30px;
            margin-right:40px;
        }
    }

    .ProjectArticle-metaAuthors,
    .ProjectArticle-metaCourses,
    .ProjectArticle-shareEntry {
        border-bottom: 1px solid map-get($colour-greys, '600');
        @include media-breakpoint-up('md') {
            border-bottom: none;
        }
        padding-top:10px;
        padding-bottom:10px;
    }

    .ProjectArticle-shareEntry {
        margin-left: 0;
        text-align: left;

        @include media-breakpoint-up('md') {
            text-align: center;
        }

        .ProjectArticle-metaTitle,
        .ProjectArticle-metaGroup {
            display: inline-block;

            @include media-breakpoint-up('md') {
                display: block;
            }
        }

        .ProjectArticle-metaGroup {
            float: right;
            margin-top: -3px;

            @include media-breakpoint-up('md') {
                float: none;
                margin-top: 0;
            }
        }

        .SocialConnect-buttonIcon {
            height: 30px;
            width: 30px;
            pointer-events: none;
        }
    }

    .ProjectArticle-metaTitle {
        font-weight:400;
        margin:0;
        color: map-get($colour-greys, '500');
        font-size:14px;
        @include media-breakpoint-up('md') {
            font-size:16px;
        }
    }

    .ProjectArticle-metaGroup {
        margin:0;
        padding:0;
        list-style:none;
    }

    .ProjectArticle-metaItem {
        margin:0;
        padding:0;
        display: inline-block;
        &:after {
            content: ","
        }
        &:last-child {
            &:after {
                content: "";
            }
        }
    }

    .ProjectArticle-body {
        margin-left:24px;
        margin-right:24px;
        @include media-breakpoint-up('md') {
            margin-left:80px;
            margin-right:80px;
        }
    }

    .ProjectArticle-headerParentLink {
        @include Link-style-quiet;
        font-size:14px;
        display:none;
        @include media-breakpoint-up('md') {
            display:block;
        }
    }

    .ProjectArticle-title {
        color: map-get($colour-greys, '300');
        font-size:30px;
        line-height:33px;
        margin-top:9px;
        margin-bottom:15px;
        position:relative;
        padding-bottom:30px;

        &:after {
            content:"";
            display:block;
            position:absolute;
            height:3px;
            width:100px;
            bottom:0;
            left:0;
            background-color: map-get($colour-brand, 'primary');
        }

        @include media-breakpoint-up('md') {
            font-size:50px;
            line-height:54px;
            margin-bottom:45px;
        }
    }

    .ProjectArticle-footer {
        margin-top:80px;
        @include media-breakpoint-up('sm') {
            display:flex;
        }
    }

    .ProjectArticle-footerGSLogo {
        width: 382px;
        height: 53px;
        margin-top: 34px;
        max-width: 100%;
    }

    .ProjectArticle-footerConnect {
        flex: 1 1 50%;
        padding: 0px 30px 30px 30px;
        @include media-breakpoint-up('sm') {
            padding-bottom: 50px;
        }
        @include media-breakpoint-up('md') {
            padding: 0px 30px 50px 80px;
        }
    }

    .ProjectArticle-footerLearnMoreTitle {
        font-weight:400;
        line-height:24px;
    }

    .ProjectArticle-footerLearnMore {
        flex: 1 1 50%;
        padding: 10px 30px 40px 30px;
        background-color:black;
        color: white;
        text-align:center;
        @include media-breakpoint-up('sm') {
            text-align:left;
            padding-bottom: 50px;
        }

        @include media-breakpoint-up('md') {
            padding: 10px 80px 50px 30px;
        }
    }
}

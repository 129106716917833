/////////////////
////  Page  ///
/////////////////
// Page

@mixin Page-sidebar {
    min-width: 0px;
    @include media-breakpoint-up(md) {
        width: 270px;
        flex: 0 0 auto;
    }
}

@mixin Page-main {
    min-width: 0px;
    @include media-breakpoint-up(md) {
        flex: 1 1 81.25%;
    }
}

// RENDER FUNCTIONS //

@mixin render-Page-classes {
    .PageIsland {
        max-width:1020px;
        margin-left:auto;
        margin-right:auto;
        background:white;
        padding-top:1px;
        margin-top:50px;
        margin-bottom:80px;
        background:white;
        padding:1px 0;

        @include media-breakpoint-up('md') {
            margin-top:85px;
        }
    }

    .PageIsland-body {
        margin-left:24px;
        margin-right:24px;
        margin-bottom:50px;
        @include media-breakpoint-up('md') {
            margin-left:80px;
            margin-right:80px;
        }
    }
}

//////////////
/// Toggle ///
//////////////

$input-line-height: 1.5;
$input-vertical-padding: 0.5em;


@mixin Toggle-style {

    // Toggle Background;

    .Toggle-label {
        background-color: map-get($colour-greys, '700');
        color:map-get($colour-greys, '400');
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        font-weight: 400;
    }

    .Toggle-input:checked + .Toggle-label {
        background-color: $colour-ui-default-link;
        color:white;
    }
}

@mixin Toggle-structure {

    .Toggle {
        position: relative;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select: none;
        display:inline-block;
    }

    .Toggle-label {
        display: inline-block;
        font-size: inherit;
        line-height: $input-line-height;
        cursor: pointer;
        padding: $input-vertical-padding 1em;
    }

    .Toggle-input {
        opacity:0;
        position: absolute;
        z-index: -1;
    }

    .Toggle--stacked {
        display: block;
    }
}

// RENDER METHOD //

@mixin render-Toggle-classes {
    @include Toggle-structure;
    @include Toggle-style;

    .Toggle--size300 {
        font-size: 12px;
    }

    .Toggle--size500 {
        font-size: 20px;
    }
}

@import "styles/4-base-ui/layout/Grid";

@mixin userBlock-centeredMainContent {
    position: relative;
    
    @include media-breakpoint-up('md') {
        padding-left:80px;
        padding-right:80px;
    }
}

@mixin userBlock-asideContent {
    @include grid-cellPaddingSizes;
    @include media-breakpoint-up('md') {
        padding-left:0;
        padding-right:0;
    }
    @include media-breakpoint-up('lg') {
        float:right;
        clear: right;
        width: grid-fractionalWidth(3);
        padding-left:24px;
        padding-right:0;
    }
}

@mixin userBlock-mainContent {
    @include grid-cellPaddingSizes;
    @include media-breakpoint-up('md') {
        padding-left:0;
        padding-right:0;
    }

    @include media-breakpoint-up('lg') {
        width: grid-fractionalWidth(8);
    }
}

@mixin userBlock-12col {
    clear: right;
    @include grid-cellPaddingSizes;
    @include media-breakpoint-up('md') {
        padding-left:0;
        padding-right:0;
    }
}

@mixin userBlock-fullWidth {
    @include media-breakpoint-up('md') {
        margin-left: -80px;
        margin-right: -80px;
    }
}

@mixin render-userContentGrid-classes {
    .UserBlockMatrix {
        @include media-breakpoint-up('sm') {
            margin-top:80px;
            margin-bottom:80px;
            .HeaderBlock:first-child {
                margin-top:-80px;
            }
        }
    }
}
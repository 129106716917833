////////////////
/// PROGRESSBAR ///
////////////////

// See: http://wtfforms.com/

@mixin ProgressBar-style {
    &[value]::-webkit-progress-bar {
      background-color:  map-get($colour-greys, '600');
      border-radius: .2rem;
    }
    &[value]::-webkit-progress-value {
      background-color: map-get($colour-state, 'info');
      border-top-left-radius: .2rem;
      border-bottom-left-radius: .2rem;
    }
    &[value="100"]::-webkit-progress-value {
      border-top-right-radius: .2rem;
      border-bottom-right-radius: .2rem;
    }

    &[value] {
        /* Remove Firefox and Opera border */
        border: 0;
        /* IE10 uses `color` to set the bar background-color */
        color: map-get($colour-state, 'info');
    }

    /* Firefox styles must be entirely separate or it busts Webkit styles. */
    @-moz-document url-prefix() {
      &[value] {
        background-color: $grey-e;
        border-radius: .2rem;
      }
      &[value]::-moz-progress-bar {
        background-color: map-get($colour-state, 'info');
        border-top-left-radius: .2rem;
        border-bottom-left-radius: .2rem;
      }
      &[value="100"]::-moz-progress-bar {
        border-top-right-radius: .2rem;
        border-bottom-right-radius: .2rem;
      }
    }

}

@mixin ProgressBar-base {

    display: inline-block;
    height: 1.5;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    //

    &[value] {
      /* Reset the default appearance */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
}

@mixin render-ProgressBar-classes {
    .ProgressBar {
        @include ProgressBar-base;
        @include ProgressBar-style;
    }
}

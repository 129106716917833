///////////////
///  TYPE   ///
///////////////

// Set up font faces.

@font-face { /* fuse_v.2_display_black */
    font-family: 'FuseV2';
    src: url('../fonts/Fuse/fuse_v.2_display_black.woff2') format('woff2'),
         url('../fonts/Fuse/fuse_v.2_display_black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face { /* fuse_v.2_display_black_italic */
    font-family: 'FuseV2';
    src: url('../fonts/Fuse/fuse_v.2_display_black_italic.woff2') format('woff2'),
         url('../fonts/Fuse/fuse_v.2_display_black_italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face { /* fuse_v.2_display_bold */
    font-family: 'FuseV2';
    src: url('../fonts/Fuse/fuse_v.2_display_bold.woff2') format('woff2'),
         url('../fonts/Fuse/fuse_v.2_display_bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face { /* fuse_v.2_display_bold_italic */
    font-family: 'FuseV2';
    src: url('../fonts/Fuse/fuse_v.2_display_bold_italic.woff2') format('woff2'),
         url('../fonts/Fuse/fuse_v.2_display_bold_italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face { /* fuse_v.2_display_book */
    font-family: 'FuseV2';
    src: url('../fonts/Fuse/fuse_v.2_display_book.woff2') format('woff2'),
         url('../fonts/Fuse/fuse_v.2_display_book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face { /* fuse_v.2_display_book_italic */
    font-family: 'FuseV2';
    src: url('../fonts/Fuse/fuse_v.2_display_book_italic.woff2') format('woff2'),
         url('../fonts/Fuse/fuse_v.2_display_book_italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


// Some useful defaults for readbale systrm text.
$_font-system-serif-stack: Georgia, "Times New Roman", Times, serif !default;
$_font-system-sans-serif-stack: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif !default;
$_font-system-monospace-stack:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;


$font-family-title: 'FuseV2', 'Helvetica Neue', Arial, sans-serif;
$font-family-body: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif !default;

$typography-title-mobile: (
    'h1': (
        'font-size': px2rem(50px),
        'line-height': px2rem(54px)
    ),
    'h2': (
        'font-size': px2rem(36px),
        'line-height': px2rem(46px)
    ),
    'h3': (
        'font-size': px2rem(24px),
        'line-height': px2rem(30px)
    ),
    'h4': (
        'font-size': px2rem(20px),
        'line-height': px2rem(28px)
    ),
    'h5': (
        'font-size': px2rem(18px),
        'line-height': px2rem(27px)
    ),
    'h6': (
        'font-size': px2rem(16px),
        'line-height': px2rem(24px)
    )
);

$typography-title-desktop: (
    'h1': (
        'font-size': px2rem(50px),
        'line-height': px2rem(54px)
    ),
    'h2': (
        'font-size': px2rem(36px),
        'line-height': px2rem(46px)
    ),
    'h3': (
        'font-size': px2rem(24px),
        'line-height': px2rem(30px)
    ),
    'h4': (
        'font-size': px2rem(20px),
        'line-height': px2rem(28px)
    ),
    'h5': (
        'font-size': px2rem(18px),
        'line-height': px2rem(27px)
    ),
    'h6': (
        'font-size': px2rem(16px),
        'line-height': px2rem(24px)
    )
);

$typography-body-mobile: (
    '600': (
        'font-size': px2rem(32px),
        'line-height': px2rem(42px)
    ),
    '500': (
        'font-size': px2rem(24px),
        'line-height': px2rem(36px)
    ),
    '400': (
        'font-size': px2rem(16px),
        'line-height': px2rem(24px)
    ),
    '300': (
        'font-size': px2rem(16px),
        'line-height': px2rem(27px)
    ),
    '200': (
        'font-size': px2rem(14px),
        'line-height': px2rem(21px)
    )
);

$typography-body-desktop: (
    '600': (
        'font-size': px2rem(32px),
        'line-height': px2rem(42px)
    ),
    '500': (
        'font-size': px2rem(24px),
        'line-height': px2rem(36px)
    ),
    '400': (
        'font-size': px2rem(18px),
        'line-height': px2rem(27px)
    ),
    '300': (
        'font-size': px2rem(16px),
        'line-height': px2rem(27px)
    ),
    '200': (
        'font-size': px2rem(14px),
        'line-height': px2rem(21px)
    ),
    '100': (
        'font-size': px2rem(12px),
        'line-height': px2rem(18px)
    )
);

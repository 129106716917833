///////////////
///  MEDIA  ///
///////////////

@mixin Media--fluid {
    max-width: 100%;
    height:auto;
}

@mixin Media--fill {
    width: 100%;
    height:auto;
}

@mixin Media--rounded {
    border-radius: 0.25em;
}

@mixin Media--circle {
    border-radius: 50%;
}

@mixin ResponsiveMedia {
    padding-bottom: 56.25%;
    background-color:  map-get($colour-greys, '600');
    vertical-align: bottom;
    position: relative;
    display: block;
    > *,
    &-content {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        vertical-align: bottom;
    }
}


@mixin render-Media-classes {
    .Media--fluid {
        @include Media--fluid;
    }

    .Media--fill {
        @include Media--fill;
    }

    .Media--rounded {
        @include Media--rounded;
    }

    .Media--circle {
        @include Media--circle;
    }

    .ResponsiveMedia {
        @include ResponsiveMedia;
    }

    .ResponsiveMedia--4x3 {
        padding-bottom: 75%;
    }

    .ResponsiveMedia--1x1 {
        padding-bottom: 100%;
    }

    .ResponsiveMediaLimiter {
        margin-left:auto;
        margin-right:auto;
    }

    .ResponsiveMedia--coverAtSmall {
        @include media-breakpoint-up('sm') {
            display:none;
        }
    }

}

@mixin render-FeatureProjectGroup-classes {

    .FeatureProjectGroup {
        @include container-500;
        position: relative;
        z-index:3;
        margin-bottom:60px;
        @include media-breakpoint-up(sm) {
            margin-bottom:90px;
        }
    }

    .FeatureProjectGroup-title {
        @include Type-sectionTitle;
    }

    .FeatureProjectGroup-list {
        .ProjectSummary {
            @include media-breakpoint-down(xxs) {
                padding:0;
            }
        }

        display:flex;
        flex-wrap: wrap;
        margin-left:-24px;
        margin-right:-24px;
        @include media-breakpoint-up(sm) {
            margin-left:-12px;
            margin-right:-12px;
        }
    }
}

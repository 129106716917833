//A flyout is just a wrapper style for a piece of content

@mixin Flag-base {
    display: flex;
}

// RENDER FUNCTIONS //

@mixin render-Flag-classes {
    .Flag {
        @include Flag-base;
    }

    .Flag--alignTop {
        align-items: flex-start;
    }

    .Flag--alignCenter {
        align-items: center;
    }

    .Flag-image {

    }

    .Flag-body {
        
    }
}

@mixin render-BlogPostPreview-classes {

    .BlogPostPreview {
        flex: 0 0 auto;
        padding: 10px;
        width: 100%;
        z-index: 10;

        @include media-breakpoint-up(xs) {
            flex: 0 0 auto;
            width: 50%;
        }
        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
            width: 33.333%;
        }
        @include media-breakpoint-up(md) {
            flex: 0 0 auto;
            width: 25%;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
            width: 20%;
        }

        &-inner {
            background: map-get($colour-greys, '600');
            position: relative;
            height: 100%;
        }
    }

    .BlogPostPreview-content {
        padding: 20px;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(sm) {
            padding: 9px 20px 20px 20px;
        }
    }

    .BlogPostPreviewImage {
        background: map-get($colour-greys, '600');
        position: relative;
        width: 100%;

        .BlogPostPreview-mediaImage {
            width: 100%
        }
    }

    .BlogPostPreview-blogType {
        position: absolute;
        display: inline-block;
        background: map-get($colour-greys, '600');
        color: map-get($colour-greys, '500');
        font-size: map-deep-get($typography-body-desktop, '100', 'font-size');
        font-weight: 600;
        line-height: map-deep-get($typography-body-desktop, '100', 'line-height');
        padding: 7px 15px 6px 12px;

        bottom: 0px;
        left: 9px;

        & > .Icon {
            margin-top: -3px;
        }

        & > span {
            margin-left: 6px;
        }
    }

    .BlogPostPreview-date {
        color: map-get($colour-greys, '400');
        font-size: map-deep-get($typography-body-desktop, '200', 'font-size');
        font-weight: 600;
        line-height: map-deep-get($typography-body-desktop, '200', 'line-height');
        margin-bottom: 0.5rem;
    }

    .BlogPostPreview-title {
        @include Type-title-h5;
        margin: 9px 0 0 0;
    }

}

///////////////////
/// GRID SYSTEM ///
///////////////////

// This is a custom built flexbox grid implementation - Work in progress

// The Girds core responsibilities are:
// - Provide a method for consitently displaying content in mulitple columns
// - Trigger flexbox layout for immediate descendents

// Grid modifiers can ber added to:
// - Remove default gutters.
// - Change the default alignment of grid items.
// - Override wrapping methods force single row layout

@import "styles/2-patterns/breakpoints";

$grid-columns: 12;

$grid-gutter-mobile: 48px;

$grid-gutter-widths: (
    'xxs': 48px,
    'xs': 40px,
    'sm': 40px,
    'md': 32px,
    'lg': 32px,
    'xlg': 32px,
    'xxlg': 32px
);


@function grid-fractionalWidth($columnCount, $totalColumns: $grid-columns) {
    @return 100% / $totalColumns * $columnCount;
} 

@mixin grid-cellPaddingSizes() {
    padding-left: $grid-gutter-mobile / 2;
    padding-right: $grid-gutter-mobile / 2;
    @each $breakpoint, $value in $grid-gutter-widths {    
        @include media-breakpoint-up($breakpoint) {
            padding-left: $value / 2;
            padding-right: $value / 2;
        }
    }
}

@mixin grid-containerOutdentSizes() {
    margin-left: $grid-gutter-mobile / -2;
    margin-right: $grid-gutter-mobile / -2;
    @each $breakpoint, $value in $grid-gutter-widths {    
        @include media-breakpoint-up($breakpoint) {
            margin-left: $value / -2;
            margin-right: $value / -2;
        }
    }
}

/* Grid horizontal alignment modifiers */

// Create a bunch of modifiers for changing the Grid's horizontal alignment at various sizes.
// TODO: review this functionality to see if it is used.

// Creates Grid cell classes for each of the $breakpoint-limits.

@mixin generate-flex-grid-cell-classes($columns: $grid-columns, $breakpoints: $breakpoint-limits) {
    @for $i from 1 through $columns {
        & > .col-#{$i} {
            flex-basis: grid-fractionalWidth($i);
            max-width: grid-fractionalWidth($i);
        }
    }
    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            @for $i from 1 through $columns {
                & > .col-#{$i}-#{$breakpoint} {
                    flex-basis: grid-fractionalWidth($i);
                    max-width: grid-fractionalWidth($i);
                }
            }
        }
    }
};



@mixin generate-horizontal-alignment-modifiers($breakpoints: $breakpoint-limits) {
    .Grid--justifyStart {
        justify-content: flex-start;
    }
    .Grid--justifyEnd {
        justify-content: flex-end;
    }
    .Grid--justifyCenter {
        justify-content: center;
    }
    .Grid--justifySpaceBetween {
        justify-content: space-between;
    }
    .Grid--justifySpaceAround {
        justify-content: space-between;
    }

    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            .Grid--justifyStart-#{$breakpoint} {
              justify-content:flex-start;
            }
            .Grid--justifyEnd-#{$breakpoint} {
              justify-content: flex-start;
            }
            .Grid--justifyCenter-#{$breakpoint} {
              justify-content: center;
            }
            .Grid--justifySpaceBetween-#{$breakpoint} {
              justify-content: space-between;
            }
            .Grid--justifySpaceAround-#{$breakpoint} {
              justify-content: space-around;
            }
        }
    }
};

// RENDER METHOD //

@mixin render-Grid-classes {

    .Grid {
        display: flex;
        flex-wrap: wrap;
        @include grid-containerOutdentSizes;

        > * {
            flex-shrink: 1;
            flex-grow: 0;
            @include grid-cellPaddingSizes;
        }

        @include generate-flex-grid-cell-classes();
    }

    // GRID MODIFIERS //

    // Remove the natural gutters on the grid
    .Grid--noGutters {
        margin-left: 0;
        margin-right: 0;
        > * {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Add some decoration to the grid so we can see how it works in the styleguide.
    .Grid--styleguideTest {
        background: $grey-e;
        > * {
            border:1px solid red;
            background: $grey-e;
        }
    }

    // Overrides the default wrapping behvaiour and force cols on the same line, even if the grid isn't wide enough.
    // This would stretch the grid beyond it's normal bounds

    .Grid--noWrap {
        flex-wrap: nowrap;
    }

    // Add the nowrap attribute, so all cols appear obn one line, but also makes the cells flexible, so the gird doesn't have to stretch.

    .Grid--flexible {
        flex-wrap: nowrap;
        > * {
            flex-shrink: 1;
        }
    }


    @include generate-horizontal-alignment-modifiers();

    /* Grid vertical alignment modifiers */

    .Grid--verticalAlignTop {
      align-items: flex-start;
    }
    .Grid--verticalAlignBottom {
      align-items: flex-end;
    }
    .Grid--verticalAlignCenter {
      align-items: center;
    }
    .Grid--verticalAlignBaseline {
      align-items: baseline;
    }

    // GRID COLUMNS //

    // Makes all cols with defined widths inflexible, so they can't grow/shrink and the grid layout is maintined. This behaviour can be overridden by adding "Grid--fleixible" modifier to the grid.

    // Moving these rules out of the "generate-float-grid-cell-classes" mixin saves us 166 declarations.
    
    .col--verticalStretch {
        display:flex;
        flex-direction: column;
        > * {
            flex-grow:1;
            align-self: stretch;
        } 
    }
}

/////////////////
// CHEECKBOXES //
/////////////////

// RESPSONSIBILITIES

// - Enables themable, customisable radio buttons.
// - Defines how the checkbox theme (colour, shadows borders etc)


// OUTSIDE SCOPE

// Do not try to style positioning, spacing or alignment here. All of that is controlled by the shared 'input-radio-checkbox-base' which affects checkboxes and radio buttons.

@mixin checkbox-input-style {
    &:before {
        border-radius: 3px;
        background: white;
        box-shadow: 0 0 0 px2em(1, 16) map-get($colour-greys, '400') inset;
        margin-top: px2em(-1, 16);
    }

    &:after {
        border-right: px2em(2, 16) solid white;
        border-bottom: px2em(2, 16) solid white;

        width: px2em(5, 16);
        height: px2em(8, 16);

        left: px2em(8, 16);
        top: px2em(18, 16);

        transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
}

@mixin render-Checkbox-classes {

    @include input-radio-checkbox-base('Checkbox');

    .Checkbox:not(#blockOldBrowsers){
        .Checkbox-input {
            & + label {
                // Add the styled Checkbox
                @include checkbox-input-style;
            }
            &:checked + label:before {
                background: $colour-ui-default-link;
                box-shadow: none;
            }
        }

        label:hover {
            &:before {
                box-shadow: 0 0 0 px2em(2, 16) $colour-ui-default-link inset;
            }
        }
    }

    @include input-generate-control-size-modifiers('Checkbox');


    .CheckboxGroup--stacked {
        margin: 0.5em 0;
        .Checkbox {
            display:block;
        }
    }
}

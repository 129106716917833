/// VARIBALES & MIXINS ///

$input-font-size: px2rem(16px);
$input-vertical-padding: 0.5em;
$input-horizontal-padding: 1em;
$input-line-height: 1.5em;

$input-target-height: $input-line-height + (2 * $input-vertical-padding);
$input-border-colour: rgba(0, 0, 0, 0.15);

$input-fieldset-offset: 1.5em;

$input-sizes: (
    '300': px2rem(12px),
    '400': px2rem(16px),
    '500': px2rem(20px),
    '600': px2rem(27px)
);

//////////////////
////  DIVIDER  ///
//////////////////


@mixin Divider-basic() {
    border-style: solid;
    border-width:  0 0 1px 0;
    border-color: map-get($colour-greys, '600');
    margin: 1.5rem 0;
}

// RENDER FUNCTIONS //

@mixin render-Divider-classes {
    .Divider {
        @include Divider-basic;
    }

    .Divider--inlineDot {
        border:0;
        display: inline-block;
        line-height: 1.5em;
        margin: 0 1em;
        &:before {
            content:'';
            background: map-get($colour-greys, '600');
            border-radius: 100%;
            width:0.4em;
            height:0.4em;
            display: block;
            position: relative;
            top:-0.125em;
        }
    }

    .TextDivider {
        position:relative;
    }
}

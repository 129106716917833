@mixin render-SocialConnect-classes {
    .SocialConnect {
        text-align:center;

        margin:20px 20px 30px 20px;
        @include media-breakpoint-up(sm) {
            margin:30px 30px 44px 30px;
        }

        @include media-breakpoint-up(sm) {
            text-align:left;
        }

        &.SocialConnect--inNews {
            display:block;
             @include media-breakpoint-up(sm) {
                display:none;
             }
        }

        &.SocialConnect--inEvents {
            display:none;
            @include media-breakpoint-up(sm) {
                display:block;
            }
        }

        &.SocialConnect--inProject {
            margin:0;
            padding-top:10px;
            position:relative;
            &:before {
                content:"";
                display:block;
                position:absolute;
                height:3px;
                width:100px;
                top:0;
                left: calc(50% - 50px);
                background-color: map-get($colour-brand, 'primary');
                @include media-breakpoint-up(sm) {
                    left:0;
                }
            }
        }
    }

    .SocialConnect-title {
        line-height:24px;
        font-weight: normal;
        color: map-get($colour-greys, '500');
    }

    .SocialConnect-titleSchoolName {
        font-weight: 700;
        color: map-get($colour-greys, '300');
    }

    .SocialConnect-buttonGroup {
        margin-top:30px;
        @include media-breakpoint-up(sm) {
            margin-top:36px;
        }
        margin-left: -7px;
        margin-right: -7px;
    }

    .SocialConnect-link {
        cursor: pointer;
        display: inline-block;
        margin: 0 3px;
        
        &:hover {
            .SocialConnect-buttonIcon {
                fill: $colour-ui-default-link-hover;
            }
        }
    }

    .SocialConnect-buttonIcon {
        height:48px;
        width:48px;
        transition: all 0.35s ease-in-out;
    }

    .SocialConnect-button {
        // background: black;
        display:inline-block;
        width:48px;
        height:48px;
        border-radius: 100%;
        color:white;
        margin-left: 7px;
        margin-right: 3px;
        margin-top:3px;
        margin-bottom:3px;

        .SocialConnect-buttonIcon {
            fill: $grey-3;
        }

        &:last-child {
            margin-right:0;
        }
        &:hover {
            .SocialConnect-buttonIcon {
                fill: $colour-ui-default-link-hover;
            }
        }
    }
}

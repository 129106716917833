/**
 * Buttons
 *
 * See: http://v4-alpha.getbootstrap.com/components/button-group/#button-toolbar
 * See: http://foundation.zurb.com/sites/docs/button-group.html
 * See: http://v4-alpha.getbootstrap.com/components/button-group/
 * See: https://www.google.com/design/spec/components/buttons.html
 * See: http://v4-alpha.getbootstrap.com/components/buttons/
 * See: http://foundation.zurb.com/sites/docs/button.html
 * See: http://codepen.io/leevigraham/pen/JLmFx
 */

$input-line-height: 24px;

@mixin Button-style() {
    font-size: $input-font-size;
    line-height: 24px;
    padding: $input-vertical-padding 1.25em;
    border:none;
    background-color: transparent;
    font-weight:700;
    color: $colour-ui-default-link;
    &:hover {
        color: lighten($colour-ui-default-link, 10);
    }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin Button--ghost {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
}

@mixin Button--rounded {
    border-radius: 1.25em;
    padding-left: px2em(15px, 16px);
    padding-right: px2em(15px, 16px);
    min-width: 2.5em;
}

@mixin Button-base {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;

    &--icon {
        padding-left: 1em;
        padding-right: 1em;
    }

    & > * {
        display: inline-block;
        vertical-align: bottom;
    }

    .Icon {
        display: inline-block;
        height: 1.5em;
        width: 1.5em;
        margin-left: -0.25em;
        margin-right: -0.25em;
    }

    .Icon + .Button-label {
        margin-left: 0.25em;
    }

    .Button-label + .Icon {
        margin-left: 0.25em;
    }

    /**
     * Remove excess padding and border in Firefox 4+
     */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    /**
     * Work around a Firefox/IE bug where the transparent `button` background
     * results in a loss of the default `button` focus styles.
     */
    &:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        z-index: 1;
    }

    &:hover {
        z-index: 2;
    }

    &:active {
        z-index: 3;
    }

    &:disabled,
    &.is-disabled {
        cursor: default;
        opacity: 0.6;
    }


}

// RENDER FUNCTIONS //

@mixin render-Button-classes {

    .Button {
        @include Button-base;
        @include Button-style;
    }

    // Button shape modifiers //

    .Button--rounded {
        @include Button--rounded;
    }

    // Button colour modifiers //

    .Button--primary {
        color: white;
        background: $colour-ui-default-link;
        &:hover {
            color: white;
            background: $colour-ui-default-link-hover;
        }
    }

    .Button--secondary {
        color: map-get($colour-brand, 'pumpkin');
        background: transparent;
        box-shadow: 0 0 0 3px map-get($colour-brand, 'pumpkin') inset;
        &:hover {
            color: white;
            background: map-get($colour-brand, 'pumpkin');
        }
    }

    .Button--tertiary {
        color: $colour-ui-default-link;
        background: transparent;
        box-shadow: 0 0 0 3px map-get($colour-greys, '600') inset;
        &:hover {
            color: map-get($colour-brand, 'pumpkin');
            background: transparent;
            box-shadow: 0 0 0 3px map-get($colour-brand, 'pumpkin') inset;
        }
    }


    .Button--success {
        color: white;
        background: map-get($colour-state, 'success');
        &:hover {
            color: white;
            background: lighten(map-get($colour-state, 'success'), 5);
        }
    }

    .Button--warning {
        color: white;
        background: map-get($colour-state, 'warning');
        &:hover {
            color: white;
            background: lighten(map-get($colour-state, 'warning'),  10);
        }
    }

    .Button--danger {
        color: white;
        background: map-get($colour-state, 'danger');
        &:hover {
            color: white;
            background: lighten(map-get($colour-state, 'danger'), 5);
        }
    }

    .Button--ghost {
        @include Button--ghost;
    }

    .Button--fill {
        display: block;
        width: 100%;
    }

    // Generate Button size modifiers like ".Button--size300"
    
    .Button--size500 {
        padding-top:0.85em;
        padding-bottom:0.85em;
    }
    @include input-generate-control-size-modifiers('Button');

}

/*
Select Boxes should be able to be styled without javascript.
We should only use javascript when we need to ass features in which case we call these inputs "Rich Selects".

See: For more details see: http://jsbin.com/batoli

Styleguide Select
*/
@mixin render-Select-classes {

    .Select {
        position: relative;
        display: inline-block;
        // padding-right: 200px;

        // The before masks out the native drop down;
        &:before {
            // content: "";
            // background-color:white;
            // bottom:1px;
            // width:2em;
            // top:1px;
            // right:3px;
            // position: absolute;
            // z-index: 2;
            // pointer-events:none;
        }

        // Create a custim dropdown arrow;
        &:after {
            border-color: map-get($colour-greys, '500');
            border-style: solid;
            border-width: 0 2px 2px 0;
            content: "";
            height: 0.5em;
            margin-top: -0.3em;
            pointer-events:none;
            position: absolute;
            right: 0.8em;
            top: 50%;
            transform: rotate(45deg);
            width: 0.5em;
            z-index: 3;
        }

        .Select-input {
            // remove the browser dropdown
            -webkit-appearance: none;
            -moz-appearance: none;
            &::-ms-expand {
                display: none;
            }
            // Add enough spacing to the right for a custom drop down icon.
            padding-right:2.2em;
            font-size: inherit;
            width:100%;
        }

        // Hover State
        // &:hover {
        //     border-color: palette(grey, dark);
        // }
    }

    @include input-generate-control-size-modifiers('Select');

    /* ------------------------------------  */
    /* START OF UGLY BROWSER-SPECIFIC HACKS */
    /* ----------------------------------  */

    // Ugly Browser Specific Hack #1
    // OPERA - Pre-Blink nix the custom arrow, go with a native select button to keep it simple. Targeted via this hack http://browserhacks.com/#hack-a3f166304aafed524566bc6814e1d5c7
    x:-o-prefocus, .Select:after {
        display:none;
    }

    // IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance,
    // IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting
    // The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .Select {
            .Select-input {
                &::-ms-expand {
                    display: none;
                }

                &:focus::-ms-value {
                    background: transparent;
                    color: map-get($colour-greys, '300');
                }

            }
        }
    }


    /* FIREFOX won't let us hide the native select arrow, so we have to make it wider than needed and clip it via overflow on the parent container. The percentage width is a fallback since FF 4+ supports calc() so we can just add a fixed amount of extra width to push the native arrow out of view. We're applying this hack across all FF versions because all the previous hacks were too fragile and complex. You might want to consider not using this hack and using the native select arrow in FF. Note this makes the menus wider than the select button because they display at the specified width and aren't clipped. Targeting hack via http://browserhacks.com/#hack-758bff81c5c32351b02e10480b5ed48e */
    /* Show only the native arrow */


    //// Comment: This hack doesn't seem neccasary any more. I;ve chaeck in FF 47 on mac and windows, and it looks like the -moz-appearence:none can remove the arrow.  ////

    // TODO: Check mobile firefox to make sure it works there too.

    // @-moz-document url-prefix() {
    //     .Select {
    //         overflow: hidden;
    //
    //         .Select-input {
    //             width: 120%;
    //             width: -moz-calc(100% + 3em);
    //             width: calc(100% + em);
    //
    //             /* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
    //             &:-moz-focusring {
    //                 color: transparent;
    //                 text-shadow: 0 0 0 #000;
    //             }
    //
    //         }
    //     }
    // }

}

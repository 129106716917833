//////////////
/// Switch ///
//////////////

$input-line-height: 1.5;
$input-vertical-padding: 0.5em;


@mixin Switch-style {

    // Switch Background;

    // Switch background
    .Switch-label:after {
        box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.3);
        border-radius: 1.5rem;
        background-color: map-get($colour-greys, '600');
        transition: background-color 0.1s ease-in;
    }

    // Switch knob
    .Switch-label:before {
        background: white;
        border-radius: 1.5rem;
        transition: all 0.1s ease-in 0s;
    }

    .Switch-input:checked + .Switch-label:after {
        background-color: $colour-ui-default-link;
    }
}

@mixin Switch-structure {

    .Switch {
        position: relative;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select: none;
    }

    .Switch-label {
        display: inline-block;
        font-size: inherit;
        line-height: $input-line-height;
        cursor: pointer;
        padding: $input-vertical-padding 0 $input-vertical-padding 4em;
        font-weight: normal;
    }

    .Switch-input {
        opacity:0;
        position: absolute;
        z-index: -1;
    }

    .Switch-label:before {
        content: "";
        display: block;
        width: 1.125em;
        height: 1.125em;
        position: absolute;
        left: 0.25em;
        z-index:2;
        top:1.125em;
        transform: translateY(-50%);
    }

    .Switch-label:after {
        content: "";
        display: block;
        width: 3em;
        height: 1.5em;
        position: absolute;
        top:1.125em;
        left:0;
        z-index:1;
        transform: translateY(-50%);
    }

    .Switch-input:checked + .Switch-label:before {
        left:1.625em;
    }
}

// RENDER METHOD //

@mixin render-Switch-classes {
    @include Switch-structure;
    @include Switch-style;

    .Switch--size300 {
        font-size: 12px;
    }

    .Switch--size500 {
        font-size: 20px;
    }
}

///////////////////////////
///  News List SUmmary  ///
///////////////////////////

@mixin render-SiteFooter-classes {
    .SiteFooter {
        @include container-500;
        margin-top: 90px;
        padding-top: 30px;
        padding-bottom: 36px;
        position: relative;
        @include media-breakpoint-up('md') {
            margin-top: 90px;
            padding-top: 36px;
            &:before {
                content:"";
                display:block;
                position:absolute;
                height:3px;
                width:6em;
                top:0;
                left:24px;
                background-color: map-get($colour-brand, 'primary');
            }
        }
    }

    .SiteFooter-inner {
        @include customGrid-wrapper;
    }

    .SiteFooter-learnMore {
        color: map-get($colour-greys, '500');
        line-height:24px;
        @include customGrid-cell;
        text-align:center;
        flex: 0 0 100%;

        @include media-breakpoint-up('sm') {
            text-align:left;
            flex: 1 0 auto;
            width: 40%;
        }
        @include media-breakpoint-up('lg') {
            flex: 1 0 auto;
            width: 60%;
        }
    }

    .SiteFooter-learnMoreCourseName {
        color: map-get($colour-greys, '300');
    }

    .SiteFooter-legal {
        @include customGrid-cell;
        text-align:center;
        font-size: 14px;
        line-height:21px;
        flex: 0 0 100%;
        @include media-breakpoint-up('sm') {
            text-align:left;
            padding-right: 54px;
            flex: 1 0 auto;
            width: 30%;
        }
        @include media-breakpoint-up('lg') {
            flex: 1 0 auto;
            width:20%;
        }
    }

    .SiteFooter-contact {
        @include customGrid-cell;
        text-align:center;
        font-size: 14px;
        line-height:21px;
        flex: 0 0 100%;
        @include media-breakpoint-up('sm') {
            text-align:left;
            flex: 1 0 auto;
            width: 30%;
        }
        @include media-breakpoint-up('lg') {
            flex: 1 0 auto;
            width:20%;
         }
    }

    .SiteFooter-gradschoolLogo {
        display: block;
        margin-top:4px;
        margin-bottom:40px;
        @include media-breakpoint-up(sm) {
            margin-bottom:20px;
            margin-top:20px;
        }
    }

    .SiteFooter-gradschoolLogoImage {
        width:230px;
        height:32px;
        @include media-breakpoint-up(sm) {
            width:280px;
            height:39px;
        }

        @include media-breakpoint-up(lg) {
            width:382px;
            height:53px;
        }
    }

    .SiteFooter-contactPromptText {
        margin-left:2px;
    }
}

@mixin render-NewsAndEvents-classes {
    .NewsAndEvents {
        position: relative;
        z-index:2;
        @include container-400;
        @include container-mobile-fullwidth;
        @include media-breakpoint-up(sm) {
            display:flex;
        }
        margin-top: -66px;
    }

    .NewsAndEvents-facebookPage{
        padding:20px;

        @include media-breakpoint-up(sm) {
            padding:30px 30px 50px 30px;
        }
        text-align: center;
        .fb-page {
            width:100%;
        }
    }

    .NewsAndEvents-facebookPageInner {
        overflow: auto;
        max-width: 100%;
        position: relative;

        .fb-page, .fb-page iframe[style], .fb-page span {
            width: 100% !important;
        }
    }

    .NewsAndEvents-facebookPageMobileBlocker {
        content: "s";
        position: absolute;
        z-index: 2;
        display: block;
        background: rgba(255, 255, 255, 0.05);
        opacity: 0.3;
        top:0;
        bottom:0;
        left:0;
        right:0;
        width:100%;
        height:100%;
        @include media-breakpoint-up(xs) {
            display:none;
        }
    }

    .NewsAndEvents-sectionTitle {
        text-transform: uppercase;
        background: $grey-3;
        color:white;
        padding: 18px 20px;
        margin:0;
        font-size:18px;
        @include media-breakpoint-up(sm) {
            padding: 18px 30px;
        }

        @include media-breakpoint-up(md) {
            font-size:24px;
        }
    }

    .NewsAndEvents-events {
        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
            width:50%;
        }
        background:white;
        display:flex;
        flex-direction: column;
    }

    .NewsAndEvents-news {

        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
            width:50%;
        }
        background:white;
        @include clearfix;
    }
}

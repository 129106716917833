///////////////
///  Icons  ///
///////////////

@mixin render-Icon-classes {
    .svg-icons {
        height: 0;
        width: 0;
        position: absolute;
        visibility: hidden;
    }

    .Icon {
        display: inline-block;
        vertical-align:middle;
        // @include display-visually-hidden;
    }
    .Icon--size300 {
        width:18px;
        height:18px;
    }

    .Icon--size200 {
        width:15px;
        height:15px;
    }

    .Icon--Chevron-up {
        fill: map-get($colour-brand, 'primary');
    }

    .Icon-Chevron--back {
         fill: map-get($colour-greys, '500');
         position: relative;
         top:-2px;
    }
}

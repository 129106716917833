
@mixin render-SiteLogo-classes {
    .SiteLogo {
        margin:0;
        padding-bottom: 0.25rem;
    }

    .SiteLogo-linkImage {
    	width: 188px;
    	height: 67px;
    }
    
    .SiteWrapper {
        position: relative;
    }
    .SiteWrapper-contentMask {
        position: absolute;
        z-index:400;
        background: rgba(0,0,0,0.4);
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:none;
    }

    .SiteWrapper-fullMask {
        position:fixed;
        z-index:400;
        background: rgba(0,0,0,0.4);
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:none;
    }
}

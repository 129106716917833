@mixin render-EventList-classes {
    .EventList {
        list-style:none;
        padding:0;
        font-size:16px;
        line-height:24px;
        flex: 1 1 auto;
        margin:20px;
        @include media-breakpoint-up(sm) {
            margin:30px 30px 50px 30px;
        }
    }

    .EventList-event {
        border-bottom: 1px solid map-get($colour-greys, '600');
        margin:0;
        padding: 8px  0 12px 0;
        @include media-breakpoint-up(sm) {
            display:flex;
        }
        &:last-child {
            @include media-breakpoint-down(xs) {
                border-bottom-color: transparent;
            }
        }
    }

    .EventList-eventDate {
        display:block;
        @include media-breakpoint-up(sm) {
            flex: 0 0 120px;
        }
    }

    .EventList-eventTitle {
        font-weight:700;
        color: map-get($colour-greys, '300');
    }
}

//////////////////
///  INPUTBOX  ///
//////////////////

// .ControlInput wraps all box style inputs "Text", "Email", "Password", "Search", "Date" etc. it provides size overrides and prefix/ siffix support


// MIXINS AND VARIABLES //

@mixin ControlInput-structure {
    display: inline-block;
    line-height: 1.5;
    vertical-align: bottom;
}

@mixin ControlInput-inline-style {
    border-style: solid;
    border-color: $input-border-colour;
    padding: calc(#{$input-vertical-padding} - 2px) 0.5em;
    color: $grey-8;
}

@mixin ControlInput-indented-style {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    z-index: 3;
    color: $grey-8;
}

// MRENDER METHOD //

@mixin render-ControlInput-classes {

    .ControlInput {
        vertical-align: middle;
        position: relative;
        font-size: $input-font-size;
    }

    .ControlInput--fill {
        width:100%;
    }

    .ControlInput--withIndentedPrefix {
        input {
            padding-left: 1.8em;
        }
        .ControlInput-prefix {
            @include ControlInput-indented-style;
            left:0;
        }
    }

    .ControlInput--withIndentedSuffix {
        input {
            padding-right: 2em;
        }

        .ControlInput-suffix {
            @include ControlInput-indented-style;
            right:0;
        }
    }

    .ControlInput--withOutdentedPrefix {
        display:inline-flex;
        .ControlInput-prefix {
            @include ControlInput-structure;
            @include ControlInput-indented-style;
            border-width: 1px 0 1px 1px;
        }
    }


    .ControlInput--withOutdentedSuffix {
        display:inline-flex;
        .ControlInput-suffix {
            @include ControlInput-inline-style;
            @include ControlInput-structure;
            border-width: 1px 1px 1px 0;
        }
    }

    @include input-generate-control-size-modifiers('ControlInput');
}

///////////////
// FUNCTIONS //
///////////////


/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

// Generic functions

@function px2rem($px){
  $remSize: $px / 16px;
  @return #{$remSize}rem;
}

@function px2em($px, $font-size){
  $emSize: $px / $font-size;
  @return #{$emSize}em;
}

////////////////
///  TABLES  ///
////////////////


// This is a custom built flexbox grid implementation - Work in progress

// The Girds core responsibilities are:
// - Provide a method for consitently displaying content in mulitple columns
// - Trigger flexbox layout for immediate descendents

// Grid modifiers can ber added to:
// - Remove default gutters.
// - Change the default alignment of grid items.
// - Override wrapping methods force single row layout

@mixin table-basic-style {
    width:100%;
    border-collapse: collapse;
    border: 1px solid map-get($colour-greys, '600');
    td, th {
        text-align:left;
        padding: 0.5rem;
        border:1px solid map-get($colour-greys, '600');
    }

    thead th {
        background-color: map-get($colour-greys, '700');
    }
    tfoot th,
    tfoot td {
        background-color: map-get($colour-greys, '700');
    }
}

@mixin render-Table-classes {
    .Table {
        @include table-basic-style;
    }
}

@import "styles/2-patterns/userBlocks";

// RENDER FUNCTIONS //

@mixin render-VideoBlock-classes {

    .VideoBlock--contained {
        @include userBlock-mainContent;
    }

    .VideoBlock--fullWidth {
        @include userBlock-fullWidth;
    }

    .VideoBlock {
        margin-top: 2em;
        margin-bottom: 3.5em;
    }
}

//////////////
// CAROUSEL //
//////////////

$Carousel-nav-width: 60px;
$Carousel-nav-height: 60px;
$Carousel-dot-size: 16px;
$Carousel-dot-spacing: 6px;

// This styling is for slick slider
@mixin carousel-style {
    .slick-dots li button:before {
        transition: all 0.2s;
        width: $Carousel-dot-size;
        height: $Carousel-dot-size;
        background: map-get($colour-brand, 'lochMara');
    }

    .slick-dots li.slick-active button:before {
        background: map-get($colour-greys, '500');
        width:8px;
        height:8px;
    }
}

@mixin carousel-base {
    /* Slider */
    // .slick-loading .slick-list {
    //     background: #fff url('./ajax-loader.gif') center center no-repeat;
    // }

    /* Arrows */
    .slick-prev,
    .slick-next {
        position: absolute;
        top: 50%;
        display: block;

        width: $Carousel-nav-width;
        height: $Carousel-nav-height;
        padding: 0;
        transform: translate(0, -50%);

        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        z-index: 140;
        font-size: 0/0;
        text-shadow: none;
        color: transparent;
    }

    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {

    }

    .slick-prev:hover:before,
    .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
        opacity: 1;
    }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
        opacity: .25;
    }

    .slick-prev:before,
    .slick-next:before {
        content:'';
        display: block;
        width: $Carousel-nav-width;
        height: $Carousel-nav-height;
        background-repeat: no-repeat;
        width:26px;
        height:26px;
        position:absolute;
        top:50%;
        left:50%;
    }

    .slick-prev:before {
         border-top: 5px solid white;
         border-left: 5px solid white;
         transform:  translateX(-50%) translateY(-50%) rotate(-45deg);
    }
    .slick-next:before {
        border-bottom: 5px solid white;
        border-right: 5px solid white;
        transform:  translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    .slick-prev {
        left: 20px;
    }

    .slick-next {
        right: 20px;
    }


    /* Dots */
    .slick-dots {
        background:white;
        bottom:-60px;
        display: block;
        list-style: none;
        margin: 0;
        padding: 10px 0;
        position: absolute;
        text-align: center;
        width: 100%;
        z-index:120;
    }

    .slick-dots li {
        position: relative;
        display: inline-block;
        width: $Carousel-dot-size;
        height: $Carousel-dot-size;
        margin: 0 $Carousel-dot-spacing;
        padding: 0;
        cursor: pointer;
    }

    .slick-dots li button {
        display: block;
        width: $Carousel-dot-size;
        height: $Carousel-dot-size;
        padding: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background: transparent;
        color: transparent;
    }

    .slick-dots li button:hover,
    .slick-dots li button:focus {
        outline: none;
    }
    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
        opacity: 1;
    }
    .slick-dots li button:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: $Carousel-dot-size;
        height: $Carousel-dot-size;
        border-radius: 100%;
    }

}

@mixin render-Carousel-classes {

    .Carousel {
        @include carousel-base;
        @include carousel-style;
        position: relative;
        z-index:1;
    }

    .slick-slider {
        // height: calc(100vh - 100px);
        // min-height: calc(100vh - 100px);
    }

    .Carousel-slide {
        display:block;
        position: relative;
        width: 100%;
        height: calc(100vh - 100px) !important;
        background-position: 50% 50%;
        background-size: cover;
    }

    .Carousel-slideCaption {
        display:none;
        position:absolute;
        padding:8px 30px;
        color:white;
        right:0;
        z-index: 4;
        font-weight:700;
        background: rgba(0,0,0,0.25);
        font-size: 12px;
        @include media-breakpoint-up(sm) {
            display:block;
        }
    }

    .Carousel--projectHome {
        // Hide everything but the first slide.
        .Carousel-slide:not(:first-child) {
            display:none;
        }
        // Show the slides when the slider is initialised.
        .slick-initialized {
            .Carousel-slide {
                display:block !important;
            }
        }
    }

}

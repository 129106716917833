@import "styles/1-settings/colours";
@import "styles/1-settings/forms";
@import "styles/1-settings/layout";
@import "styles/1-settings/type";

@import "styles/2-patterns/typography";
@import "styles/2-patterns/backgrounds";
@import "styles/2-patterns/inputs";

@import "styles/4-base-ui/form/Button";
@import "styles/4-base-ui/layout/Divider";
@import "styles/4-base-ui/type/Link";
@import "styles/4-base-ui/type/List";
@import "styles/4-base-ui/layout/Table";


//////////
// BASE //
//////////

// This file provides default element level styling <h1> to <h6> sizes, <a> link colour, <body> background colour etc. It should only target simple HTML tags, Not classes or ID's.

// See: http://foundation.zurb.com/sites/docs/base-typography.html
// See: http://v4-alpha.getbootstrap.com/content/reboot/
// See: https://www.google.com/design/spec/style/typography.html
// See: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss#L157



*,
*:before,
*:after {
    box-sizing: border-box;
}

// html, body {
//     height: 100%;
//     min-height: 100%;
// }

html {
    font-family: $font-family-body;
}

body {
    
    color: map-get($colour-greys, '400');

    font-size: map-deep-get($typography-body-mobile, '400', 'font-size');
    line-height: map-deep-get($typography-body-mobile, '400', 'line-height');

    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-body-desktop, '400', 'font-size');
        line-height: map-deep-get($typography-body-desktop, '400', 'line-height');
    }

    // &.Body--hasFixedMobileHeader {
    //     padding-top:50px;
    //     @include media-breakpoint-up(sm) {
    //         padding-top: 0;
    //     }
    // }
}


h1 {
    @include Type-title-h1;
}

h2 {
    @include Type-title-h2;
}

h3 {
    @include Type-title-h3;
}

h4 {
    @include Type-title-h4;
}

h5 {
    @include Type-title-h5;
}

h6 {
    @include Type-title-h6;
}

p {
    
}

ul {
    @include List-style-standard;
}

ol {
    @include List-style-standard;
}

a {
    @include Link-style-standard;
}

hr {
    @include Divider-basic;
}

code, pre {
    font-family: $_font-system-monospace-stack;
}

pre {
    padding: 1rem;
    background-color: $grey-f;
}

blockquote {
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    border-left: 2px solid map-get($colour-greys, '500');

    footer {
        font-weight: 400;
    }
}

table {
    @include table-basic-style;
}

figure {
    margin-left:0;
    margin-right:0;
    figcaption {
        padding:12px;
    }
}


@include render-basic-input-elements;


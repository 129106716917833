////////////////
///  LAYOUT  ///
////////////////

$base-unit: 0.25rem;
$layout-gutterWidth: 48px;
$layout-columnPadding: $layout-gutterWidth / 2;

/////////////////////
///  BREAKPOINTS  ///
/////////////////////

/* Breakpoint limits taken from Google Material Design guidelines */
/* See: https://material.google.com/layout/responsive-ui.html#responsive-ui-breakpoints */

$breakpoint-limits: (
  'xxxs': 120px,
  'xxs': 480px,
  'xs': 600px,
  'sm': 840px,
  'md': 960px,
  'lg': 1280px,
  'xlg': 1440px,
  'xxlg': 1600px
);

// $breakpoint-limits: (
//   'xxs': px2rem(480px),
//   'xs': px2rem(600px),
//   'sm': px2rem(840px),
//   'md': px2rem(960px),
//   'lg': px2rem(1280px),
//   'xlg': px2rem(1440px),
//   'xxlg': px2rem(1600px)
// );

// Photoswipe Path
$pswp__assets-path: "../images/photoswipe/";

////////////////////
///  TYPOGRAPHY  ///
////////////////////

// This file defines the size and spacing for all commonly used typography on the site:
//
// - font-size
// - line-height
// - margin-top
// - margin-bottom
//
// Decoration like colours/borders etc should be defined elsewhere.

@import "styles/1-settings/type";
@import "styles/2-patterns/functions";
@import "styles/2-patterns/breakpoints";

////////////////
///  TITLES  ///
////////////////

// @mixin Type-title-display {
//     font-size: map-deep-get($typography-title-mobile, 'display', 'font-size');
//     line-height: map-deep-get($typography-title-mobile, 'display', 'line-height');
//     font-weight: 800;
//     margin-top: 2.125em;
//     margin-bottom: 1em;
//     @include media-breakpoint-up(xs) {
//         font-size: map-deep-get($typography-title-desktop, 'display', 'font-size');
//         line-height: map-deep-get($typography-title-desktop, 'display', 'line-height');
//     }
// }

@mixin Type-title-h1 {
    font-size: map-deep-get($typography-title-mobile, 'h1', 'font-size');
    line-height: map-deep-get($typography-title-mobile, 'h1', 'line-height');
    font-weight: 900;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-family: $font-family-title;
    text-transform: uppercase;
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-title-desktop, 'h1', 'font-size');
        line-height: map-deep-get($typography-title-desktop, 'h1', 'line-height');
    }
}

@mixin Type-title-h2 {
    font-size: map-deep-get($typography-title-mobile, 'h2', 'font-size');
    line-height: map-deep-get($typography-title-mobile, 'h2', 'line-height');
    font-weight: 900;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-family: $font-family-title;
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-title-desktop, 'h2', 'font-size');
        line-height: map-deep-get($typography-title-desktop, 'h2', 'line-height');
    }
}

@mixin Type-title-h3 {
    font-size: map-deep-get($typography-title-mobile, 'h3', 'font-size');
    line-height: map-deep-get($typography-title-mobile, 'h3', 'line-height');
    font-weight: 900;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-family: $font-family-title;
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-title-desktop, 'h3', 'font-size');
        line-height: map-deep-get($typography-title-desktop, 'h3', 'line-height');
    }
}

@mixin Type-title-h4 {
    font-size: map-deep-get($typography-title-mobile, 'h4', 'font-size');
    line-height: map-deep-get($typography-title-mobile, 'h4', 'line-height');
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-family: $font-family-title;
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-title-desktop, 'h4', 'font-size');
        line-height: map-deep-get($typography-title-desktop, 'h4', 'line-height');
    }
}

@mixin Type-title-h5 {
    font-size: map-deep-get($typography-title-mobile, 'h5', 'font-size');
    line-height: map-deep-get($typography-title-mobile, 'h5', 'line-height');
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-family: $font-family-title;
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-title-desktop, 'h5', 'font-size');
        line-height: map-deep-get($typography-title-desktop, 'h5', 'line-height');
    }
}

@mixin Type-title-h6 {
    font-size: map-deep-get($typography-title-mobile, 'h6', 'font-size');
    line-height: map-deep-get($typography-title-mobile, 'h6', 'line-height');
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-family: $font-family-title;
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-title-desktop, 'h6', 'font-size');
        line-height: map-deep-get($typography-title-desktop, 'h6', 'line-height');
    }
}


///////////////////
///  BODY COPY  ///
///////////////////

// @mixin Type-body-600 {
//     font-size: map-deep-get($typography-body-mobile, '600', 'font-size');
//     line-height: map-deep-get($typography-body-mobile, '600', 'line-height');
//     margin-top: 1em;
//     margin-bottom: 1em;
//     @include media-breakpoint-up(xs) {
//         font-size: map-deep-get($typography-body-mobile, '600', 'font-size');
//         line-height: map-deep-get($typography-body-mobile, '600', 'line-height');
//     }
// }

@mixin Type-body-600 {
    font-size: map-deep-get($typography-body-mobile, '600', 'font-size');
    line-height: map-deep-get($typography-body-mobile, '600', 'line-height');
    margin-bottom: px2rem(36px);
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-body-desktop, '600', 'font-size');
        line-height: map-deep-get($typography-body-desktop, '600', 'line-height');
        margin-bottom: px2rem(36px);
    }
}

@mixin Type-body-500 {
    font-size: map-deep-get($typography-body-mobile, '500', 'font-size');
    line-height: map-deep-get($typography-body-mobile, '500', 'line-height');
    margin-bottom: px2rem(36px);
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-body-desktop, '500', 'font-size');
        line-height: map-deep-get($typography-body-desktop, '500', 'line-height');
        margin-bottom: px2rem(36px);
    }
}


@mixin Type-body-400 {
    font-size: map-deep-get($typography-body-mobile, '400', 'font-size');
    line-height: map-deep-get($typography-body-mobile, '400', 'line-height');
    margin-bottom: px2rem(30px);
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-body-desktop, '400', 'font-size');
        line-height: map-deep-get($typography-body-desktop, '400', 'line-height');
        margin-bottom: px2rem(30px);
    }
}

@mixin Type-body-300 {
    font-size: map-deep-get($typography-body-mobile, '300', 'font-size');
    line-height: map-deep-get($typography-body-mobile, '300', 'line-height');
    margin-bottom: px2rem(30px);
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-body-desktop, '300', 'font-size');
        line-height: map-deep-get($typography-body-desktop, '300', 'line-height');
        margin-bottom: px2rem(30px);
    }
}

@mixin Type-body-200 {
    font-size: map-deep-get($typography-body-mobile, '200', 'font-size');
    line-height: map-deep-get($typography-body-mobile, '200', 'line-height');
    margin-bottom: px2rem(21px);
    @include media-breakpoint-up(xs) {
        font-size: map-deep-get($typography-body-desktop, '200', 'font-size');
        line-height: map-deep-get($typography-body-desktop, '200', 'line-height');
        margin-bottom: px2rem(21px);
    }
}

@mixin Type-sectionTitle {
    text-align:center;
    font-size:24px;
    line-height: 32px;
    margin-bottom:24px;
    @include media-breakpoint-up(sm) {
        font-size: 36px;
        line-height: 46px;
        margin-bottom:24px;
    }
}


@mixin render-Type-classes {
    .Type-sectionTitle {
        @include  Type-sectionTitle;
    }
    .Type-title-h1 {
        @include  Type-title-h1;
    }

    .Type-title-h2 {
        @include  Type-title-h2;
    }

    .Type-title-h3 {
        @include  Type-title-h3;
    }

    .Type-title-h4 {
        @include  Type-title-h4;
    }

    .Type-title-h5 {
        @include  Type-title-h5;
    }

    .Type-title-h6 {
        @include  Type-title-h6;
    }

    // .Type-body-600 {
    //     @include  Type-body-600;
    // }

    .Type-body-500 {
        @include  Type-body-500;
    }

    .Type-body-400 {
        @include  Type-body-400;
    }

    .Type-body-300 {
        @include  Type-body-300;
    }

    .Type-body-200 {
        @include  Type-body-200;
    }
}
/////////////////
///  COLOURS  ///
/////////////////

// Neutral colours used for copy and UI elements


$grey-1: #0F1012;
$grey-2: #1E2025;
$grey-3: #2D3138;
$grey-4: #3D414A;
$grey-5: #4C525D;
$grey-6: #5B6270;
$grey-7: #6B7382;
$grey-8: #7C8493;
$grey-9: #8E95A3;
$grey-a: #A1A7B2;
$grey-b: #B4B8C1;
$grey-c: #C6CAD1;
$grey-d: #D9DBE0;
$grey-e: #ECEDEF;
$grey-f: #F6F7F9;

$colour-greys: (
    '100': $grey-1,
    '200': $grey-2,
    '300': $grey-4,
    '400': $grey-6,
    '500': $grey-9,
    '600': $grey-f,
    '700': #FFFFF
);

// Colours for showing a statuses, used in buttons, alerts etc.

$colour-brand: (
    'primary': #1090D5,
    'primary--shade100': #3FA6DD,
    'primary--shade200': #87C7EA,
    'primary--shade300': #C3E3F4,
    'primary--shade400': #E7F3FA
);

$colour-state: (
    'nominal': #696d79,
    'info': #0589c0,
    'success': #45AA05,
    'warning': #FFB700,
    'danger': #F23C2E
);

// Default interactivity colours.
$colour-ui-default-link: map-get($colour-brand, 'primary');
$colour-ui-default-link-hover: map-get($colour-brand, 'primary--shade100');
$colour-ui-default-link-visited: map-get($colour-greys, '300');
$colour-ui-default-disabled: map-get($colour-greys, '500');
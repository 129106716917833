///////////////////////////
///  News List SUmmary  ///
///////////////////////////

@mixin MobileSiteHeader-style {
    background-color:white;
    border-bottom: 1px solid map-get($colour-greys, '600');
    
    .MobileSiteHeader-title {
        height:28px;
        line-height: 28px !important;
        font-size: 1rem !important;
    }
    .MobileSiteHeader-titleLink {
        color: map-get($colour-brand, 'pumpkin');
        vertical-align:middle;
        display:inline-block;
    }

    .MobileSiteHeader-titleLinkText {
        @include display-visually-hidden;
    }
    
    .MobileSiteHeader-tool {
        &:hover .MobileSiteHeader-toolIcon {
            fill: map-get($colour-brand, 'pumpkin');
        }
    }
    .MobileSiteHeader-toolIcon {
        width: 24px;
        height: 24px;
        fill: map-get($colour-greys, '400');
        vertical-align:middle;
    }
}

@mixin MobileSiteHeader-toolbar-base($block: '.MobileSiteHeader') {

    display:flex;
    align-items: center;

    #{$block}-tool {
        line-height:24px;
        padding:12px 16px;
    }

    #{$block}-toolIcon {
        width: 24px;
        height: 24px;
    }
}

@mixin MobileSiteHeader-base {
    position: fixed;
    z-index:50;
    top:0;
    left:0;
    right:0;

    &-title {
        margin:0;
        text-align: center;
        flex: 1 1 auto;
    }

    @include media-breakpoint-up('md') {
         display: none;
    }
}


@mixin render-MobileSiteHeader-classes {
    .MobileSiteHeader-inner {
        @include MobileSiteHeader-toolbar-base(".MobileSiteHeader");
    }
    

    .MobileSiteHeader {
        @include MobileSiteHeader-base;
        @include MobileSiteHeader-style;
    }
}

@import "styles/4-base-ui/form/Button";
@import "styles/4-base-ui/form/InputBox";

////////////
// INPUTS //
////////////

// Proides basic stylling for all form input elements, including buttons

// Checkbox and radio sizing


@mixin input-generate-control-size-modifiers($block, $array: $input-sizes) {
    @each $modifier, $value in $array {
        .#{$block}--size#{$modifier} {
            font-size: $value;
        }
    }
}

@mixin input-generate-control-state-modifiers($block, $array: $colour-state) {
    @each $modifier, $value in $array {
        .#{$block}--#{$modifier} {
            color: $value;
        }
    }
}

@mixin input-radio-checkbox-base($block: 'Checkbox') {
    // Set up the default sizing line heights, padding and behaviours of Checkboxes and RadioButtons

    .#{$block} {
        display: inline-block;
        position: relative;
        margin-right: 1em;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select: none;
    }

    .#{$block}-input {
        position: absolute;
        left:0;
        top:em(2, 16);

        &:checked + label {
            opacity: 1;
            font-weight:500;
        }
    }

    .#{$block}-label {
        position:relative;
        padding-left: 1.5em;
        cursor: pointer;
        font-weight: normal;
        opacity: 0.8;
    }

    .#{$block}:not(#blockOldBrowsers){
        .#{$block}-input {
            // hide The checkbox or radio;
            opacity: 0;

            // The radio/checkbox oultine (like a bordered box, or circle);
            & + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.75em;
                width: 1em;
                height: 1em;
            }

            // The 'On status' of the radio/checkbox oultine (like a dot, or circle);
            & + label:after {
                content: '';
                position: absolute;
                opacity:0;
            }

            // Turn the checkmark on when the input is checked;
            &:checked + label:after {
                opacity: 1;
            }

            // Set up disabled styles
            &.disabled + label,
            &[disabled] + label {
                opacity: 0.5;
            }

            &.disabled:hover + label:after,
            &[disabled] + label:after {
              opacity: 0;
            }

            &.disabled + label:before,
            &[disabled] + label:before {
                box-shadow: none;
                border: 2px solid map-get($colour-greys, '600');
            }

        }
    }
}


/// RENDER FUNCTIONS ///

@mixin render-basic-input-elements {

    input[type=date],
    input[type=datetime-local],
    input[type=email],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=time],
    input[type=url] {
        @include InputBox-sizing;
        height:$input-target-height;
    }
    
    // Remove the "clear" button from IE10 search
    input[type=search]::-ms-clear {
        display: none;
    }

    textarea {
        @include InputBox-sizing;
        @include InputBox-style;
        width:100%;
    }

    select {
        @include InputBox-sizing;
        @include InputBox-style;
        padding:$input-vertical-padding 1em;
        height:$input-target-height;
    }

    input[type=radio],
    input[type=checkbox],
    input[type=range]  {
        vertical-align: top;
        margin-right: 0.5em;
        margin-top: 1em;
    }

    input[type=file] {
        vertical-align: top;
        margin-top: 0.875em;
    }

    label {
        font-weight: 700;
        vertical-align: top;
        padding:$input-vertical-padding 0.5em $input-vertical-padding 0;
        line-height: 1.5;
        display: inline-block;
    }

    label input[type=radio],
    label input[type=checkbox] {
        margin-top:0.25em;
    }

    legend {
        padding: 0;
        display: table;
        position: relative;
        top:$input-fieldset-offset;
        margin-bottom:$input-fieldset-offset;
    }

    fieldset {
        border: none;
        padding: 0;
        margin: 0;
        min-width: 0;
    }

    body:not(:-moz-handler-blocked) fieldset {
        display: table-cell;
    }


    button {
        appearance: none;
        -webkit-appearance: none;
        border:none;
        background:none;
        @include Link-style-standard;
    }

    input[type=search]::-webkit-search-cancel-button,
    input[type=search]::-webkit-search-decoration,
    input[type=search]::-webkit-search-results-button,
    input[type=search]::-webkit-search-results-decoration {
        -webkit-appearance:none;
    }
}

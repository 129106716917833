///////////////
// CONTAINER //
///////////////

// A Container wraps blocks of site content, they are commonly used with 'Grid' blocks, but can be used without them.

// The Containers core responsibilities are:
// - provide horizontal padding to prevent text from running into the the edges of the window
// - Center page content with margin: 0 auto.
// - Provide enough horizontal padding to prevent the negative margins applied to grids from triggering horizontal scrolling.

// Container modifiers can ber added to:
// - Limit the max width of a content block.
// - potentially limit the width of a content block at specific breakpoints - adaptive design.

@import "styles/2-patterns/breakpoints";

@mixin container-400 {
    max-width:1020px;
    margin-left:auto;
    margin-right:auto;
    padding-left: $layout-columnPadding;
    padding-right: $layout-columnPadding;
}

@mixin container-500 {
    max-width:1440px;
    margin-left:auto;
    margin-right:auto;
    padding-left: $layout-columnPadding;
    padding-right: $layout-columnPadding;
}

@mixin container-mobile-fullwidth {
    padding-left:0;
    padding-right:0;
    @include media-breakpoint-up(md) {
        padding-left: $layout-columnPadding;
        padding-right: $layout-columnPadding;
    }
}


@mixin render-Container-classes {

    .Container--capped400 {
        @include container-400;
    }

    .Container--capped500 {
        @include container-500;
    }

}

$Control-title-margin-top: 1.5em;

@mixin Control-box {
    margin-top:0.5em;
    margin-bottom:0.5em;
}

@mixin Control-floated {
    vertical-align: top;
    @include media-breakpoint-up('sm') {
        display:inline-block;
        margin-right: 2rem;
    }
}

@mixin Control-title {
    font-size: 16px;
    display: block;
    padding:0;
    font-weight: 700;
    margin-bottom:0;
    margin-top: $Control-title-margin-top;
}

@mixin Control-description {
    font-size: 13px;
    color: map-get($colour-greys, '300');
    p:last-child {
        margin-bottom:0.2em;
    }
}

@mixin Control-error {
    color: map-get($colour-state, danger);
    font-size:13px;
    margin:0.25em 0;
    padding:0;
    list-style:none;
    li {
        margin:0;
        padding:0;
    }
}

@mixin render-Control-classes {
    .Control {
        margin: 1em 0;
        &.Control--NewsletterSubscriptionFormInput {
            margin: 0.75rem 0 0.75rem 0;
        } 
    }

    .Form-body .Grid {
        margin-bottom: -0.5em;
    }

    .Control--inline {
        @include Control-floated;
    }

    .Control--indented {
        margin-left:1.5em;
    }

    .Control-title {
        @include Control-title;
        margin: 0.5em 0em;
    }

    .Control-title--inline {
        margin:0;
        display:inline;
    }

    .Control-title--required {
        &:after {
            content:'*';
            margin-left:5px;
        }
    }

    .Control-title--size300 {
        font-size: 13px;
        color: map-get($colour-greys, '300');
        margin-bottom: 0;
        margin-top: 1em;
        font-weight: 600;
    }

    .Control-description {
        @include Control-description;
    }

    .Control-errors {
        @include Control-error;
        display:none;
    }

    .Control-hasErrors {
        .Control-errors {
            display:block;
        }

        .InputBox {
            box-shadow: 0 -2px 0 0 map-get($colour-state, 'danger')  inset, 0 0 0 1px $input-border-colour inset;
        }
    }
}

@import "styles/1-settings/colours";
@import "styles/1-settings/forms";
@import "styles/1-settings/layout";
@import "styles/1-settings/type";

//////////////////
///  INPUTBOX  ///
//////////////////

// .InputBox wraps all box style inputs "Text", "Email", "Password", "Search", "Date" etc. it provides size overrides and prefix/ siffix support


// MIXINS AND VARIABLES //

@mixin InputBox-style {
    border: 0;
    background: white;
    border-radius: 0;
    box-shadow: 0 0 0 1px $input-border-colour inset;
    outline: none;
    -webkit-appearance: none;
    &:focus {
        box-shadow:  0 0 0 1px map-get($colour-brand, 'primary') inset;
        z-index: 2;
    }
}

@mixin InputBox-sizing {

    max-width: 100%;
    box-sizing: border-box;
    line-height: $input-line-height;
    vertical-align: top;
    font-size: $input-font-size;
    padding: $input-vertical-padding $input-horizontal-padding;
}

// MRENDER METHOD //

@mixin render-InputBox-classes {
    .InputBox {
        @include InputBox-style;

        @each $modifier, $value in $input-sizes {
            &.InputBox--size#{$modifier} {
                font-size: $value;
            }
        }

    }

  

    .InputBox--fill {
        display: block;
        width:100%;
    }
}

///////////
// RADIO //
//////////

// ABOUT
// - Enables themable, customisable radio buttons.

// RESPSONSIBILITIES
// - Defines how the radio theme (colour, shadows borders etc)

// EXTERNAL (Do this stuff elsewhere)
// - Checkbox Positioning, spacing and alignment (All of that is controlled by the 'input-radio-radio-base' mixin which affects checkboxes and radio buttons.

@mixin radio-input-style {
    &:before {
        border-radius: 100%;
        background: white;
        box-shadow: 0 0 0 px2em(2, 16) map-get($colour-greys, '400') inset;
        margin-top:-1px;
    }

    &:after {
        background-color: $colour-ui-default-link;
        border-radius: 100%;

        width:px2em(6, 16);
        height:px2em(6, 16);

        left: 0.5em;
        top: 1.25em;
        transform: translateX(-50%) translateY(-50%);
        margin-top:-1px;
    }
}

@mixin render-Radio-classes {

    @include input-radio-checkbox-base('Radio');

    .Radio:not(#blockOldBrowsers){
        .Radio-input {
            & + label {
                // Add the styled Radio
                @include radio-input-style;
            }
            &:checked + label:before {
                box-shadow: 0 0 0 px2em(2, 16) $colour-ui-default-link inset;
            }
        }

        label:hover {
            &:before {
                box-shadow: 0 0 0 px2em(2, 16) $colour-ui-default-link inset;
            }
        }
    }

    @include input-generate-control-size-modifiers('Radio');

    .RadioGroup--stacked {
        margin: 0.5em 0;
        .Radio {
            display:block;
        }
    }
}

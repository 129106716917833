@import "styles/4-base-ui/form/Control";

////////////
/// FORM ///
////////////

@mixin Form-row-spacing {
    position: relative;
    &:before {
        content: '';
        border-top: 1px solid map-get($colour-greys, '600');
        width:100%;
        top: -2rem;
        position: absolute;
    }
    margin: 4rem 0;
}


@mixin render-Form-classes {
    .Form {
       
    }

    .Form-header {
        margin: 1.5em 0;
        padding-bottom: 0.5rem;
    }

    .Form-header-title {
        @include Type-title-h3;
        margin:0;
    }

    .Form-header-description {
       
    }

    .Form-body {
        @include Form-row-spacing;
    }

    .Form-body {
        > .Grid:first-child {
            margin-top: -1.5em;
        }
        // > *:first-child {
        //     margin-top: 0;
        //     > *:first-child {
        //         margin-top: 0;
        //     }

        // }
        // > fieldset:first-child {
        //     margin-top: -1.5em;
        // }
    }

    .Form-body-title {
        font-size: px2rem(22px);
        line-height: px2rem(24px);
        margin-top:2em;
        margin-bottom: 0.5em;
    }

    .Form-body-description {
        font-size:13px;
        margin-bottom: 0.5em;
    }

    .Form-footer {
        @include Form-row-spacing;
    }

    .Form-footerActions {
        text-align:right;
    }
}

/// GENERIC DISPLAY SYSTEM ///

@mixin clearfix {
  &:after, &:before {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin wrapMargins {
  &:after, 
  &:before {
    content: "";
    display:table;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
}


@mixin aspect-ratio2($width, $height) {
    vertical-align:top;
    > * {
        vertical-align:top;
    }

    &:before {
        display: block;
        content: "";
        padding-top: 56.25%;
        float:right;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
}


@mixin display-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

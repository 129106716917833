.Shade {
    background-color: map-get($colour-greys, '600');
    padding-bottom:250px;
    margin-bottom: -160px;
    border-top: 1px solid transparent;
    position: relative;
    z-index: 0; /* Fixes Shade element appearing over filter buttons */

    &.Shade--noOffset {
        padding-bottom:1px;
        margin-bottom:0;
    }
    @include media-breakpoint-up(sm) {
        padding-bottom:290px;
    }
}

.Shade-background {
    background-size:cover;
    background-attachment:fixed;
    background-position:50% 50%;
    width:100%;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity:0.2;
    z-index:1;
}

//////////////
///  List  ///
//////////////

@mixin List-style-standard {
    margin: 1.5em 0;
    padding:0 0 0 1.5em;
    li {
        margin: 1em 0;
    }
}

@mixin List-description-inline {
    @include clearfix;
    dt {
        float:left;
        clear:left;
        display:inline-block;
        white-space: pre-line;
        margin-right: 10px;
    }

    dd {
        float:left;
        margin:0;
        padding:0;
    }
}

@mixin List-description-stacked {
    @include clearfix;
    margin: 1.75em 0;
    line-height:1.75;
    padding:0;
    dt {
        display:inline-block;
        margin-right: 0;
        margin-top: 0.75em;
    }

    dd {
        margin:0;
        padding:0;
    }
}

@mixin List-inline($block: '.Menu-List') {
    margin-left:0;
    padding-left:0;
    
    #{$block}-list {
        display: inline-block;
        list-style: none;
        margin:0;
        padding:0;
    }
    #{$block}-item {
        margin-right: 2em;
        &:last-child {
            margin-right: 0;
        }
    }

    #{$block}-item,
    #{$block}-link {
        display: inline-block;
    }

    #{$block}-link {
        margin-right:1em;
        display: inline-block;
    }

    #{$block}-item:last-child,
    #{$block}-item:last-child #{$block}-link {
        margin-right:0;
    }
}

@mixin List-dot-separated($block: '.Menu') {
    // Style the separator
    #{$block}-item {
        position: relative;
        &:first-child {
            &:before {
                display:none;
            }
        }
    }

    #{$block}-link {
        margin-right:2em;
    }

    #{$block}-item:before {
        content:'';
        position: absolute;
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-radius: 100%;
        width: px2em(5px, 14px);
        height: px2em(5px, 14px);
        top: calc(50% - 1px);
        transform: translateY(-50%);
        left: (2em / -2) - 0.3em;
        background: map-get($colour-greys, '600');
    }
}

@mixin List-slash-separated($block: '.Menu') {

    // Style the separator
    #{$block}-item:before {
        content: '/';
        font-weight: 400;
        color: map-get($colour-greys, '600');
        position: relative;
        left: -0.5em;
        cursor: auto;
    }
    #{$block}-item:first-child {
        &:before {
            display:none;
        }
    }
}

@mixin List-expandableIcon() {
    content:'';
    position: absolute;
    display: block;
    top:1.3em;
    right:0.5em;
    width:8px;
    height:8px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform: rotate(-45deg);
}

@mixin List-arrow-affixed($block: ".Menu") {
    #{$block}-link {
        padding-right:20px;
    }
    #{$block}-item {
        position: relative;
    }
    #{$block}-item:after {
        @include  List-expandableIcon;
        border-color: map-get($colour-greys, '400');
    }
    #{$block}-item--isExpanded {
        &:after {
            transform: rotate(45deg);
        }
    }
}

@mixin List-icon-description($block: ".Menu") {
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem 0;
    padding: 0;
    dt {
        margin:5px 0;
        padding:0;
        flex: 0 0 40px;
    }

    dd {
        margin:7px 0;
        padding:0;
        flex: 1 1 calc(100% - 40px);
    }

}

@mixin render-List-classes {
    .List {
        @include List-style-standard;
    }
    .List--description {
        @include List-description-stacked;
        
        dt {
            font-weight: 700;
            color: map-get($colour-greys, '400');
        }
    }

    .List--size300 {
        font-size: map-deep-get($typography-body-desktop, '300', 'font-size');
    }

    .List--iconDescription {
        @include List-icon-description;
    }
}

@mixin render-ProjectSummaryGroup-classes {

    .ProjectSummaryGroup {
        @include container-500;
        z-index: 999; /* Fixes Shade element appearing over filter buttons */
    }

    .ProjectSummaryGroup-header {
        @include media-breakpoint-up(sm) {
            display:flex;
            justify-content: space-between;
            align-items: baseline;
        }
    }

    .ProjectSummaryGroup-filter {
        z-index: 1; /* Fixes Shade element appearing over filter buttons */
    }

    .ProjectSummaryGroup-filterDesktopControl {
        display:none;
        margin-bottom: 15px;

        @include media-breakpoint-up(sm) {
            display:block;
        }
    }

    .ProjectSummaryGroup-filterMobileControl {
        display:flex;
        justify-content: space-between;
        margin-bottom:30px;
        @include media-breakpoint-up(sm) {
            display:none;
        }
    }

    .ProjectSummaryGroup-filterMobileSelect {
        flex: 1 1 auto;
    }

    .ProjectSummaryGroup-filterMobileControlLabel {
        flex: 0 0 70px;
        font-weight:400;
        margin-right:20px;
        position: relative;
        &:after {
            content:'';
            position: absolute;
            bottom:0;
            left:0;
            width: 30px;
            border-bottom: 2px solid map-get($colour-brand, 'primary');
        }
    }

    .ProjectSummaryGroup-filterControl {
        font-weight: 400;
        font-size:16px;
        color: map-get($colour-greys, '500');
        &.ProjectSummaryGroup-filterControl--isCurrent {
            font-weight: 700;
            color: map-get($colour-brand, 'primary');
            position: relative;
            &:after {
                position: absolute;
                content:'';
                right: 2px;
                left:2px;
                bottom:-2px;
                border-bottom: 2px solid map-get($colour-brand, 'primary');
            }
        }
        &:focus {
            outline: 0;
        }
    }

    .ProjectSummaryGroup-title {
        margin-top:0;
        @include Type-sectionTitle;
        @include media-breakpoint-up(sm) {
            text-align:left;
        }
    }

    .ProjectSummaryGroup-listMessage {
        display: none;
        padding: 0 9px 9px 9px;
        z-index: 3;

        @include media-breakpoint-up(sm) {
            padding: 12px 15px;
        }

        &.ProjectSummaryGroup-filterControl--isCurrent {
            display: block;
        }
    }

    .ProjectSummaryGroup-list {
        display:flex;
        flex-wrap: wrap;
        margin-left:-10px;
        margin-right:-10px;
        @include media-breakpoint-up(sm) {
            margin-left:-12px;
            margin-right:-12px;
        }
    }

}

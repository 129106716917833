@import "styles/2-patterns/userBlocks";

// RENDER FUNCTIONS //

@mixin render-TextBlock-classes {

    .TextBlock {
        margin-top:2rem;
        margin-bottom:3rem;
    }  
}


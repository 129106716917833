@import "styles/4-base-ui/form/Control";

@mixin render-ControlGroup-classes {
    .ControlGroup {
        @include clearfix;
    }

    .ControlGroup--inline {
        @include Control-floated;
    }

    .ControlGroup--gapless {
        vertical-align: top;
        display: inline-flex;
        flex-wrap: wrap;

        > *:not(:last-child) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;

        }

        > *:not(:first-child) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            margin-left: -1px;

            .Toggle-label {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        > .Button:not(:first-child) {
            box-shadow: 1px 0 0 0 rgba(255, 255, 255, 0.3) inset;
            margin-left: 0;
        }
    }

    .ControlGroup--inline {
        @include Control-floated;
    }

    .ControlGroup-title {
        @include Control-title;

    }

    .ControlGroup-description {
        @include Control-description
    }

    legend.ControlGroup-title {
        // We have to elatively offest legends to correct for weird browser positioning. To counteract this
        top: $Control-title-margin-top;
        margin-bottom: $Control-title-margin-top;
    }

    .ControlGroup-errors {
        @include Control-error;
    }
}

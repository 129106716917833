@mixin render-ProjectSummary-classes {

    .ProjectSummary {
        padding:10px;
        flex: 0 0 auto;
        width: 50%;

        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
            width: 33.333%;
        }
        @include media-breakpoint-up(md) {
            flex: 0 0 auto;
            width: 25%;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
            width: 20%;
        }

        &.ProjectSummary--withInlineMeta {
            .ProjectSummary-meta {
                opacity:1;
            }
            .ProjectSummary-link {
                padding-bottom:66px;
            }
        }

        &.ProjectSummary--white {
            @include media-breakpoint-down('xs') {
                .ProjectSummary-meta {
                    background:white;
                    color: map-get($colour-greys, '400');
                    padding:10px 0;
                    position: relative;
                    opacity: 1;
                }
                .ProjectSummary-metaSource {
                    display:none;
                }
            }
        }
    }

    .ProjectSummary-link {
        position: relative;
        display: block;
        &:hover {
            .ProjectSummary-image {
                opacity: 0.7;
            }
            .ProjectSummary-meta {
                opacity: 1;
            }
            .ProjectSummary-imageWrapper {
                background: black;
            }
        }
    }

    .ProjectSummary-imageWrapper {
        display: block;
        padding-bottom: 75%;
        position: relative;
        will-change: opacity;
        transition: background 0.3s;
        background: transparent;

        // &:after {
        //     content:'';
        //     position: absolute;
        //     top:0;
        //     bottom:0;
        //     left:0;
        //     right:0;
        //     background: black;
        //     z-index:-1;
        // }
    }

    .ProjectSummary-image {
        will-change: opacity;
        transition: opacity 0.3s;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width: 100%;
        height: 100%;
        background: black;

    }

    .ProjectSummary-meta {
        will-change: opacity;
        transition: opacity 0.3s;
        font-size:14px;
        line-height:21px;
        background:black;
        color:white;
        padding: 12px 14px 14px 14px;
        margin:0;

        position:absolute;
        opacity:0;
        bottom:0;
        left:0;
        right:0;
        width:100%;
        z-index:2;
    }

    .ProjectSummary-metaTitle {
        display:block;
    }
    .ProjectSummary-metaSource {
        font-weight:400;
        display:block;
    }



}

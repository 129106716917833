
@mixin render-DesktopSiteHeader-classes {
    .SiteHeader {
        margin: 0 20px;
        @include media-breakpoint-up(md) {
             margin: 0 50px;
        }
    }

    .SiteHeader-inner {
        position: relative;
        z-index:2;
        background:white;
        display:flex;
        max-width:1400px;
        margin: 0 auto;
    }

    .SiteHeader-logoWrapper {
        position: relative;
        width:90px;
        flex: 0 0 90px;
        @include media-breakpoint-up(md) {
            width:186px;
            flex: 0 0 186px;
        }
    }

    .SiteHeader-logo {
        position:absolute;
        display:block;
        top:0;
        background:black;
        padding:1px;
        vertical-align:top;
        cursor:pointer;
    }

    .SiteHeader-logoImage {
        display:block;
        @include media-breakpoint-up(md) {
            width:118px;
            height:114px;
            margin:35px;
        }

        margin:14px;
        width:63px;
        height:61px;
    }

    .SiteHeader-title {
        margin: 16px 20px;
        font-size:18px;
        line-height:1.2em;
        color: map-get($colour-greys, '500');
        flex: 1 1 auto;
        @include media-breakpoint-up(md) {
             font-size:24px;
             margin: 36px 50px;
        }
    }
    .SiteHeader-titleLink {
        color: map-get($colour-greys, '500');
    }

    .SiteHeader-prompt {
        display:none;
        font-size:14px;
        line-height:18px;
        margin: 26px 0 16px 0;
        flex: 0 0 270px;
        @include media-breakpoint-up(md) {
            display:block;
        }
    }
}

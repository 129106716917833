@mixin container-400 {
    max-width:1020px;
    margin-left:auto;
    margin-right:auto;
    padding-left:24px;
    padding-right:24px;
}

@mixin container-500 {
    max-width:1440px;
    margin-left:auto;
    margin-right:auto;
    padding-left:24px;
    padding-right:24px;
}

@mixin customGrid-wrapper {
    display:flex;
    flex-wrap: wrap;
    margin-left:-10px;
    margin-right:-10px;
    @include media-breakpoint-up(sm) {
        margin-left:-12px;
        margin-right:-12px;
    }
}

@mixin customGrid-cell {
    padding-left:10px;
    padding-right:10px;
    @include media-breakpoint-up(sm) {
        padding-left:12px;
        padding-right:12px;
    }
}
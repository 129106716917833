/////////////
///  Link  ///
/////////////

//  standard blue link on white, turns orange on hover
@mixin Link-style-standard {
    transition: all 0.2s;
    color: $colour-ui-default-link;
    font-weight: 600;
    text-decoration:none;
    &:hover {
        color: $colour-ui-default-link-hover;
        text-decoration:none;
        .Link-text {
            box-shadow: inset 0 -0.375em 0 map-get($colour-greys, '600');
        }
        .Link-arrowIcon {
             fill: map-get($colour-brand, 'primary');
        }
    }
    &--isCurrent {
        
    }

}


//  dark grey link on white, turns orange on hover
@mixin Link-style-secondary {
    color: map-get($colour-greys, '200');
    font-weight: 600;
    text-decoration:none;
    &:hover {
        color: $colour-ui-default-link-hover;
        text-decoration:none;
        .Link-text {
            box-shadow: inset 0 -0.375em 0 map-get($colour-greys, '600');
        }
    }

    &--isCurrent {
        
    }
}

//  Light grey link on white, turns orange on hover
@mixin Link-style-quiet {
    color: map-get($colour-greys, '500');
    font-weight: 400;
    text-decoration:none;
    &:hover {
        color: $colour-ui-default-link-hover;
        text-decoration:none;
        .Link-text {
            box-shadow: inset 0 -0.375em 0 map-get($colour-greys, '600');
        }
    }
    &--isCurrent {
        
    }
}

// White link on orange, tursn light yellow on hover 
@mixin Link-style-inverse {
    color: white;
    font-weight: 700;
    text-decoration:none;
    &:hover {
        color: map-get($colour-brand, 'primary');
        text-decoration:none;
        .Link-text {
            box-shadow: inset 0 -0.375em 0 rgba(map-get($colour-brand, 'primary'), 0.5);
        }
    }
    &--isCurrent {
        
    }
}



@mixin render-Link-classes {
    .Link {
        @include Link-style-standard;
    }

    .Link--secondary {
        @include Link-style-secondary;
    }

    .Link--quiet {
        @include Link-style-quiet;
    }

    .Link--inverse {
        @include Link-style-inverse;
    }
}

@import "styles/2-patterns/userBlocks";

// RENDER FUNCTIONS //

@mixin render-ImageBlock-classes {

    // .ImageBlock--contained {
    //     @include userBlock-mainContent;
    // }

    // .ImageBlock--fullWidth {
    //     @include userBlock-fullWidth;
    // }

    .ImageBlock-carousel {
        margin-top:3em;
        margin-bottom:5em;
    }

    .ImageBlock-thumbnailGrid {

        margin-top:3em;
        margin-bottom:3em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .ImageBlock-image {
            flex: 1 1 25%;
            padding: 10px;
            max-width: 25%;
        }
    }

    .ImageBlock-imageList {
        margin-top:3em;
        margin-bottom:1em;
        .ImageBlock-image {
            margin-top:2em;
            margin-bottom:2em;
        }
    }


    .ImageBlock-imageCaption {
        font-size:14px;
        color: map-get($colour-greys, '400');
    }

    .ImageBlock--contained {
        .ImageBlock-imageCaption {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .ImageBlock--fullWidth {
        .ImageBlock-imageCaption {
            padding-left: 20px;
            padding-right: 20px;
            margin-top:1.25rem;
            @include userBlock-centeredMainContent;
        }
    }
}

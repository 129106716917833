///////////////////
/// File Upload ///
///////////////////


@mixin render-FileUpload-classes {
    .FileUpload {
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 2.5rem;
    }
    .FileUpload input {
        min-width: 14rem;
        margin: 0;
        opacity: 0;
    }
    .FileUpload-custom {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        height: 2.5rem;
        padding: .5rem 1rem;
        line-height: 1.5;
        color: map-get($colour-greys, '300');
        background-color: white;
        border: .075rem solid map-get($colour-greys, '600');
        border-radius: .25rem;
        user-select: none;
    }
    .FileUpload-custom:after {
      content: "Choose file...";
    }
    .FileUpload-custom:before {
      position: absolute;
      top: -.075rem;
      right: -.075rem;
      bottom: -.075rem;
      z-index: 6;
      display: block;
      content: "Browse";
      height: 2.5rem;
      padding: .5rem 1rem;
      line-height: 1.5;
      color: map-get($colour-greys, '300');
      background-color: map-get($colour-greys, '600');
      border: .075rem solid map-get($colour-greys, '600');
      border-radius: 0 .25rem .25rem 0;
    }

    /* Focus */
    .FileUpload input:focus ~ .FileUpload-custom {

    }

}
